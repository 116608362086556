import React, { useContext } from 'react'
import { StyleSheet, ImageBackground, Image, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { hpContext } from '../../../../HPContext';

function HpPointCompare(props) {
  const { t } = useTranslation();
  const isWide = useContext(hpContext).isWide;

  const pointHeight = props.pointHeight;
  const titleSize = props.titleSize;
  const contentSize = props.contentSize;

  const windowDimensions = useContext(hpContext).windowDimensions;
  const windowWidth = windowDimensions.width;


  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      paddingVertical: isWide? 20 : 10,
    },
    leftContainer: {
      flex: isWide? 1.5 : 1.2,
      height: pointHeight,
      clipPath: 'polygon(0 0, 85% 0, 100% 100%, 0% 100%)', // 右側のビューのクリップパスを反対方向に
    },
    rightContainer: {
      flex: 1,
      height: pointHeight,
    },
    wrap: {
      flex: 1,
      alignItems: 'flex-start',
      justifyContent: 'center',
      marginLeft: isWide? '10%' : '5%',
    },
    head: {
      width: isWide? windowWidth*0.1 : windowWidth*0.25,
      aspectRatio: 2, // 画像のアスペクト比縦横(1:2)を保持
    },
    title: {
      color: 'white',
      fontSize: titleSize,
      fontWeight: 'bold',
      marginTop: isWide? '2%':'4%',
      marginBottom: isWide? '1%':'4%',
    },
    content: {
      color: 'white',
      fontSize: contentSize,
      textAlign: isWide? 'auto':'flex-start',
    },

    overlay: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // 半透明の白色オーバーレイ
      blurRadius: 10, // ぼかしの強さ
    },
  });


  return (
    <View style={styles.container}>
      <ImageBackground
        source={{uri: '/assets/img/point/compare.jpg'}}
        style={styles.leftContainer}
      >
        <View style={styles.overlay}></View>
      </ImageBackground>

      <View style={styles.rightContainer}>
        <View style={styles.wrap}>
          <Image
            defaultSource={'./assets/img/point/number/04.png'}
            style={styles.head}
          />
          {/* 比較 */}
          <Text style={styles.title}>{t('top.point.compare.title')}</Text>
          {/* 動画を並べて見比べる */}
          <Text style={styles.content}>{t('top.point.compare.description')}</Text>
        </View>
      </View>
    </View>
  );
};

export default HpPointCompare;